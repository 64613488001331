<template>
  <nav
    class="navbar navbar-expand-lg navbar-dark bg-dark gradient-primary py-1 z-[1]"
  >
    <div class="container-fluid container-xl">
      <router-link class="navbar-brand py-0" to="/">
        <span class="logo-name">FluxBeam</span>
      </router-link>
      <button
        id="toggle"
        class="navbar-toggler collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse text-center"
        id="navbarSupportedContent"
      >
        <ul class="navbar-nav me-auto mb-lg-0">
          <li class="nav-item d-flex align-items-center opacity-50">
            <router-link class="nav-link" to="/app/swap"
              ><i class="fa fa-right-left nav-icon me-1"></i
              ><span>SWAP</span></router-link
            >
          </li>

          <li class="nav-item dropdown text-start text-lg-center opacity-50">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fa fa-coins nav-icon me-1"></i><span>DEFI</span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link
                to="/app/liquidity"
                class="nav-item d-flex align-items-center"
              >
                <span class="nav-link"
                  ><i class="fa fa-droplet nav-icon me-1"></i
                  ><span>LIQUIDITY</span></span
                >
              </router-link>
              <router-link
                to="/app/pools"
                class="nav-item d-flex align-items-center"
              >
                <span class="nav-link"
                  ><i class="fa fa-water nav-icon me-1"></i
                  ><span>POOLS</span></span
                >
              </router-link>
              <router-link
                to="/app/tokens"
                class="nav-item d-flex align-items-center"
              >
                <span class="nav-link"
                  ><i class="fa fa-coins nav-icon me-1"></i
                  ><span>TOKENS</span></span
                >
              </router-link>
            </ul>
          </li>

					<li class="nav-item d-flex align-items-center opacity-50">
						<router-link class="nav-link" to="/app/bridge"
						>
							<i class="fa fa-bridge nav-icon me-1"></i
							>
							<span>BRIDGE</span></router-link
						>
					</li>

          <!--					<li class="nav-item dropdown">-->
          <!--						<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">-->
          <!--							<i class="fa fa-image nav-icon me-1"></i><span>NFTs</span>-->
          <!--						</a>-->
          <!--						<ul class="dropdown-menu" aria-labelledby="navbarDropdown">-->
          <!--							<router-link to="/app/nfts/hot_or_not" class="nav-item d-flex align-items-center">-->
          <!--								<span class="nav-link"><i class="fa fa-robot nav-icon me-1"></i><span>FLUXBOTS</span></span>-->
          <!--							</router-link>-->

          <!--							<router-link to="" class="nav-item d-flex align-items-center disabled">-->
          <!--								<span class="nav-link">😏 <span>Coming Soon</span></span>-->
          <!--							</router-link>-->
          <!--						</ul>-->
          <!--					</li>-->

          <!--					<li class="nav-item dropdown">-->
          <!--						<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">-->
          <!--							<i class="fa fa-robot nav-icon me-1"></i><span>FLUXBOT</span>-->
          <!--						</a>-->
          <!--						<ul class="dropdown-menu" aria-labelledby="navbarDropdown">-->
          <!--							<router-link to="/bot" class="nav-item d-flex align-items-center">-->
          <!--								<span class="nav-link"><i class="fa fa-boxes-stacked nav-icon me-1"></i><span>ABOUT</span></span>-->
          <!--							</router-link>-->
          <!--							<router-link to="/bot/tutorials" class="nav-item d-flex align-items-center">-->
          <!--								<span class="nav-link"><i class="fa fa-video nav-icon me-1"></i><span>TUTORIALS</span></span>-->
          <!--							</router-link>-->
          <!--&lt;!&ndash;							<router-link to="/app/points" class="nav-item d-flex align-items-center">&ndash;&gt;-->
          <!--&lt;!&ndash;								<span class="nav-link"><i class="fa fa-trophy nav-icon me-1"></i><span>POINTS</span></span>&ndash;&gt;-->
          <!--&lt;!&ndash;							</router-link>&ndash;&gt;-->
          <!--						</ul>-->
          <!--					</li>-->

          <li class="nav-item d-flex align-items-center opacity-50">
            <router-link to="/app/tools" class="nav-link"
              ><i class="fa fa-tools nav-icon me-1"></i
              ><span>TOOLS</span></router-link
            >
          </li>

          <li class="nav-item d-flex align-items-center">
            <a
              href="https://fluxbot.xyz"
              class="text-decoration-none"
              target="_blank"
              ><i class="fa fa-robot nav-icon me-1"></i><span>FLUXBOT</span></a
            >
          </li>
        </ul>

        <ul class="navbar-nav ms-auto mb-lg-0">
          <li class="nav-item d-flex align-items-center">
            <router-link
              class="nav-link"
              to="/app/settings"
              aria-label="Settings"
              ><i class="fa fa-cog nav-icon me-1"></i
              ><span>SETTINGS</span></router-link
            >
          </li>
          <li class="nav-item d-flex align-items-center">
            <router-link class="nav-link" to="/app/profile" aria-label="Profile"
              ><i class="fa fa-person nav-icon me-1"></i
              ><span>PROFILE</span></router-link
            >
          </li>
          <li
            class="nav-item d-flex align-items-center justify-content-center"
            v-if="!isDevice() || $store.state.wallet.connected"
          >
            <MultiWallet
              @connected="onWalletConnect"
              class="login wow fadeIn ms-0 ms-md-4"
            ></MultiWallet>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import MultiWallet from "@/components/wallet/MultiWallet.vue";

export default {
  name: "Navbar",
  components: { MultiWallet },
  data() {
    return {
      interval: null,
    };
  },
  methods: {
    logout: function (e) {
      e.preventDefault();
      this.$store.commit("wallet/clear_wallet");
      localStorage.removeItem("walletName");
    },
    onWalletConnect: function () {
      console.log("Navbar::onWalletConnect");
      this.refreshBalance();
    },
    refreshBalance: function () {
      if (!this.$store.state.wallet.connected) return;

      this.$store.dispatch("cache/refreshUserBalance");
    },
    closeNav: function () {
      document.querySelector("#toggle").classList.toggle("collapsed");
      const content = document.querySelector("#navbarSupportedContent");
      content.classList.toggle("collapsing");
      content.classList.remove("show");
    },
    isDevice: function () {
      return window.innerWidth < 992;
    },
  },
  mounted() {
    this.interval = setInterval(() => this.refreshBalance(), 20000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  beforeRouteUpdate() {
    console.log("route update");
    this.closeNav();
  },
};
</script>

<style scoped>
.nav-icon {
  transform: scale(0.8);
}
.collapse {
  visibility: visible;
}
</style>
