import {FLUXB, WSOL} from "@/api/token_swap/constants";
import {web3} from "@project-serum/anchor";

export default {
	namespaced: true,
	state: () => ({
		loading: false,
		tokenAAmount: 0,
		tokenBAmount: 0, //Mainly only used for liquidity page
		tokenA: localStorage.getItem("swap:default_token_a") ? JSON.parse(localStorage.getItem("swap:default_token_a")) : {
			address: WSOL, decimals: 9, name: "Solana", symbol: "SOL", verified: true,
			programID: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
		},
		tokenB: localStorage.getItem("swap:default_token_b") ? JSON.parse(localStorage.getItem("swap:default_token_b")) : {
			address: FLUXB, decimals: 5, name: "FluxBot", symbol: "FLUXB", verified: true,
			image: "https://bafybeiakyhwwesdvxpikjn4kyqddira3hjpjfcn26ycrrezt65bfa7r3gm.ipfs.nftstorage.link",
			programID: "TokenzQdBNbLqP5VEhdkAS6EPFLC1PHnBqCXEpPxuEb"
		},
	}),
	mutations: {
		setTokenA(state, token) {
			state.tokenA = token
			localStorage.setItem("swap:default_token_a", JSON.stringify(token))
		},
		setTokenB(state, token) {
			state.tokenB = token
			localStorage.setItem("swap:default_token_b", JSON.stringify(token))
		},
		setTokenAAmount(state, amount) {
			state.tokenAAmount = amount;
		},
		setTokenBAmount(state, amount) {
			state.tokenBAmount = amount;
		},
		set_loading(state, loading) {
			state.loading = loading;
		},
		clear(state) {
			//TODO
			state.loading = false
		},
	},
	actions: {
		setTokenA({dispatch, commit }, token) {
			commit('setTokenA', token)
			if (token.decimals === 0)
				dispatch('getTokenADecimals')
		},
		setTokenB({dispatch, commit }, token) {
			commit('setTokenB', token)
			if (token.decimals === 0)
				dispatch('getTokenBDecimals')
		},

		async getTokenADecimals({state, commit,rootState}) {
			const resp = await rootState.solana.client.getParsedAccountInfo(new web3.PublicKey(state.tokenA.address), "processed")
			const token = state.tokenA
			token.decimals = resp?.data?.parsed?.info?.decimals || 0

			commit('setTokenA', token)
		},

		async getTokenBDecimals({state, commit,rootState}) {
			const resp = await rootState.solana.client.getParsedAccountInfo(new web3.PublicKey(state.tokenB.address), "processed")
			const token = state.tokenA
			token.decimals = resp?.data?.parsed?.info?.decimals || 0

			commit('setTokenA', token)
		},
	}
}
