import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: '',
		component: () => import(/* webpackChunkName: "home" */ '../layouts/DefaultLayout.vue'),
		children: [
			{
				path: '/',
				name: 'Home',
				component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
			},
			{
				path: '/auth/login',
				name: 'Login',
				component: () => import(/* webpackChunkName: "home" */ '../views/auth/Login.vue')
			},
			{
				path: '/terms-conditions',
				name: 'Terms & Conditions',
				component: () => import(/* webpackChunkName: "privacy" */ '../views/privacy/TermsConditions.vue')
			},
			{
				path: '/privacy-policy',
				name: 'Privacy Policy',
				component: () => import(/* webpackChunkName: "privacy" */ '../views/privacy/PrivacyPolicy.vue')
			},
			{
				path: 'bot',
				name: 'FluxBot',
				component: () => import(/* webpackChunkName: "bot" */ '../views/bot/HomeView.vue'),
			},
			{
				path: 'bot/tutorials',
				name: 'FluxBot Tutorials',
				component: () => import(/* webpackChunkName: "bot" */ '../views/bot/TutorialView.vue'),
			},
		]
	},
	{
		path: '/app',
		name: '',
		component: () => import(/* webpackChunkName: "auth_app" */ '../layouts/AuthenticatedLayout.vue'),
		children: [
			{
				path: 'bridge',
				name: 'Bridge',
				component: () => import(/* webpackChunkName: "bridge" */ '../views/bridge/Bridge.vue')
			},
			{
				path: 'swap',
				name: 'Swap',
				component: () => import(/* webpackChunkName: "token" */ '../views/token/TokenSwap.vue')
			},
			{
				path: 'trade',
				name: 'Trade',
				component: () => import(/* webpackChunkName: "trade" */ '../views/trading/Trade.vue')
			},
			{
				path: 'tokens',
				name: 'Tokens',
				component: () => import(/* webpackChunkName: "token" */ '../views/token/TokenIndex.vue')
			},
			{
				path: 'tokens/:id',
				name: 'Token Show',
				component: () => import(/* webpackChunkName: "token" */ '../views/token/TokenShow.vue')
			},
			{
				path: 'tokens/:id/transfer',
				name: 'Transfer Token',
				component: () => import(/* webpackChunkName: "token" */ '../views/token/TransferView.vue')
			},
			{
				path: 'liquidity',
				name: 'Liquidity',
				component: () => import(/* webpackChunkName: "liquidity" */ '../views/liquidity/LiquiditySwap.vue')
			},
			{
				path: 'pools',
				name: 'Pool Index',
				component: () => import(/* webpackChunkName: "pool" */ '../views/pools/PoolIndex.vue')
			},
			{
				path: 'migrations',
				name: 'Migration Index',
				component: () => import(/* webpackChunkName: "migration" */ '../views/migration/MigrationIndex.vue')
			},
			{
				path: 'markets',
				name: 'Openbook Markets',
				component: () => import(/* webpackChunkName: "pool" */ '../views/pools/MarketIndex.vue')
			},
			{
				path: 'markets/create',
				name: 'Market Create',
				component: () => import(/* webpackChunkName: "pool" */ '../views/pools/MarketCreate.vue')
			},
			{
				path: 'pools/create',
				name: 'Pool Create',
				component: () => import(/* webpackChunkName: "pool" */ '../views/pools/PoolCreate.vue')
			},
			{
				path: 'pools/:id',
				name: 'Pool Show',
				component: () => import(/* webpackChunkName: "pool" */ '../views/pools/PoolShow.vue')
			},
			{
				path: 'nfts/pools/create',
				name: 'NFT Pool Create',
				component: () => import(/* webpackChunkName: "nft_pool" */ '../views/pools/NFTPoolCreate.vue')
			},
			{
				path: 'nfts/pools',
				name: 'NFT Pools',
				component: () => import(/* webpackChunkName: "nft_pool" */ '../views/nft/pools/PoolIndex.vue')
			},
			{
				path: 'nfts/pools/:id',
				name: 'NFT Pool',
				component: () => import(/* webpackChunkName: "nft_pool" */ '../views/nft/pools/PoolShow.vue')
			},
			{
				path: 'profile',
				name: 'Profile',
				component: () => import(/* webpackChunkName: "profile" */ '../views/profile/ProfileIndex.vue')
			},
			{
				path: 'profile/tokens/:id',
				name: 'Profile Token',
				component: () => import(/* webpackChunkName: "profile" */ '../views/profile/ProfileTokenShow.vue')
			},
			{
				path: 'settings',
				name: 'Settings',
				component: () => import(/* webpackChunkName: "settings" */ '../views/SettingView.vue')
			},
			{
				path: 'lockers/:id',
				name: 'Locker',
				component: () => import(/* webpackChunkName: "lockers" */ '../views/lockers/FluxLockerShow.vue'),
			},
			{
				path: 'tools',
				name: '',
				component: () => import(/* webpackChunkName: "tools" */ '../views/tools/ToolsIndex.vue'),
			},
			{
				path: 'tools/token_create',
				name: 'Token Create',
				component: () => import(/* webpackChunkName: "tools_token_create" */ '../views/tools/TokenCreate.vue')
			},
			{
				path: 'tools/token_create_v1',
				name: 'Token Create v1',
				component: () => import(/* webpackChunkName: "tools_token_v1_create" */ '../views/tools/TokenCreateV1.vue')
			},
			{
				path: 'tools/sol_wrap',
				name: 'SOL Wrap',
				component: () => import(/* webpackChunkName: "tools_wrap_sol" */ '../views/tools/WrapSol.vue')
			},
			{
				path: 'tools/fee_manager',
				name: 'Fee Manager',
				component: () => import(/* webpackChunkName: "tools_fee_manager" */ '../views/tools/FeeManager.vue')
			},
			{
				path: 'tools/fee_manager_automated',
				name: 'Automated Fee Manager',
				component: () => import(/* webpackChunkName: "fee_manager_automated" */ '../views/tools/FeeManagerAdvanced.vue')
			},
			{
				path: 'tools/token_manager',
				name: 'Token Manager',
				component: () => import(/* webpackChunkName: "tools_token_manager" */ '../views/tools/TokenManager.vue')
			},
			{
				path: 'tools/token_metadata',
				name: 'Token Metadata',
				component: () => import(/* webpackChunkName: "tools_token_manager" */ '../views/tools/MetadataUpdate.vue')
			},
			{
				path: 'tools/token_transfer',
				name: 'Token Transfer',
				component: () => import(/* webpackChunkName: "tools_token_transfer" */ '../views/token/TransferView.vue')
			},
			{
				path: 'tools/token_burn',
				name: 'Token Burn',
				component: () => import(/* webpackChunkName: "tools_token_burn" */ '../views/token/BurnView.vue')
			},
			{
				path: 'tools/token_mint',
				name: 'Token Mint',
				component: () => import(/* webpackChunkName: "tools_token_mint" */ '../views/token/MintView.vue')
			},
			{
				path: 'tools/token_withheld_amount',
				name: 'Token Withheld Amount',
				component: () => import(/* webpackChunkName: "tools_withheld_amount" */ '../views/tools/WithheldAmount.vue')
			},
			{
				path: 'tools/token_verify',
				name: 'Token Verification',
				component: () => import(/* webpackChunkName: "tools_token_verify" */ '../views/tools/VerifyView.vue')
			},
			{
				path: 'tools/token_airdrop',
				name: 'Token Airdrop',
				component: () => import(/* webpackChunkName: "tools_token_airdrop" */ '../views/tools/TokenAirdrop.vue')
			},
			{
				path: 'tools/token_migration',
				name: 'Token Migration',
				component: () => import(/* webpackChunkName: "tools_token_migration" */ '../views/migration/MigrationCreate.vue')
			},
			{
				path: 'tools/token_locker',
				name: 'Token Locker',
				component: () => import(/* webpackChunkName: "tools_token_locker" */ '../views/tools/TokenLocker.vue')
			},
			// {
			// 	path: 'tools/token_launch',
			// 	name: 'Token Launch',
			// 	component: () => import(/* webpackChunkName: "tools_token_launch" */ '../views/tools/TokenLaunch.vue')
			// },
			{
				path: 'tools/spl22/bridge',
				name: 'SPL22 Bridge',
				component: () => import(/* webpackChunkName: "tools_token_launch" */ '../views/tools/TokenLaunch.vue')
			},
			{
				path: 'spl22/pools',
				name: 'SPL22 Pools',
				component: () => import(/* webpackChunkName: "tools_token_launch" */ '../views/spl22/PoolIndex.vue')
			},
			{
				path: 'spl22/pools/:id',
				name: 'SPL22 Pool',
				component: () => import(/* webpackChunkName: "tools_token_launch" */ '../views/spl22/PoolShow.vue')
			},
			{
				path: 'nfts/hot_or_not',
				name: 'Hot Or Not',
				component: () => import(/* webpackChunkName: "nfts_hot_or_not" */ '../views/nfts/hot_or_not/HotOrNot.vue')
			},

			{
				path: 'nfts/hot_or_not/rankings',
				name: 'Hot Or Not Ranking',
				component: () => import(/* webpackChunkName: "nfts_hot_or_not" */ '../views/nfts/hot_or_not/Rankings.vue')
			},

			{
				path: 'nfts/hot_or_not/:id',
				name: 'Hot Or Not | Show',
				component: () => import(/* webpackChunkName: "nfts_hot_or_not" */ '../views/nfts/hot_or_not/Show.vue')
			},

			{
				path: 'points',
				name: 'FLUX Points Leaderboard',
				component: () => import(/* webpackChunkName: "points" */ '../views/points/LeaderboardView.vue')
			},

		]
	},
	{
		path: '/bot',
		name: '',
		component: () => import(/* webpackChunkName: "auth_app" */ '../layouts/BotLayout.vue'),
		children: [
			{
				path: 'portfolio/:id',
				name: 'Portfolio',
				component: () => import(/* webpackChunkName: "points" */ '../views/bot/PortfolioView.vue')
			},
		]
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior() {
		return {top: 0}
	},
	routes
})

export default router
