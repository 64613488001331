import {web3} from "@project-serum/anchor";
import {Token2022Client} from "@/api/token2022/token_2022";
import {FluxBeamClient} from "@/api/fluxbeam/fluxBeamClient";
import Client from "@/api/token_swap/client";
import {RugCheckClient} from "@/api/rugcheck/client";

export default {
	namespaced: true,
	state: () => ({
		endpoint: "",
		cluster: "mainnet-beta",
		commitment: "processed",
		client: null,
		tokenClient: null,
		fluxClient: new FluxBeamClient(),
		rugCheckClient: new RugCheckClient(),
		swapClient: null,
		jitoClient: null,
		priorityFees: {"open": 1, "close": 1, "high": 1, "low": 1, "average": 1, "pct75": 1, "pct25": 1}
	}),
	mutations: {
		set_endpoint(state, url) {
			console.log("Using endpoint: ", url)
			state.endpoint = url
			state.client = new web3.Connection(state.endpoint, "processed")
			state.jitoClient = new web3.Connection("https://mainnet.block-engine.jito.wtf/api/v1/transactions", "processed")
			state.tokenClient = new Token2022Client(state.client)
			state.swapClient = new Client(state.client)
			state.cluster = url.indexOf("devnet") > -1 ? "devnet" : "mainnet-beta"
		},
		set_commitment(state, commitment) {
			state.commitment = commitment
			state.client = new web3.Connection(state.endpoint, state.commitment)
			state.tokenClient = new Token2022Client(state.client)
			state.swapClient = new Client(state.client)
		},
		set_cluster_fees(state, fees) {
			state.priorityFees = fees
		}
	},
	getters: {
		fees: function (state) {
			return state.priorityFees
		},
	},
	actions: {
		fetchClusterFees: async function ({commit, state, dispatch}) {
			const fees = await state.fluxClient.getPriorityFees()
			if (fees)
				commit("set_cluster_fees", fees)

			setTimeout(() => {
				dispatch("fetchClusterFees")
			}, 5000)
		}
	}
}
