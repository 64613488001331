export default {
	namespaced: true,
	state: () => ({
		loading: false,
		aToB: false,
		settings: JSON.parse(localStorage.getItem('settings')),
		available_routes: [],
		selected_nft: null,
		selected_collection: {},
		selected_token: JSON.parse(localStorage.getItem("selected_token")) || {
			address: "So11111111111111111111111111111111111111112",
			image_src: "/img/tokens/solana.png",
			name: "Solana",
			symbol: "SOL"
		},
		selected_route: {},
	}),
	mutations: {
		set_available_routes(state, quote = {swapMode: 0, routes: []}) {
			let validQuotes = []
			let noQuote = []

			for (let i = 0; i < quote.routes.length; i++) {
				let a = quote.routes[i]
				a.feeAmount = (a.marketRoute.outAmount * (a.marketRoute.LPFee.pct / 100))
				if (a.marketRoute.outAmount > 0 && a.marketRoute.label !== "magiceden")
					validQuotes.push(a)
				else
					noQuote.push(a)
			}

			if (quote.swapMode === 0) {
				state.available_routes = validQuotes.sort((a, b) => {
					return (a.marketRoute.outAmount + a.feeAmount) - (b.marketRoute.outAmount + b.feeAmount)
				})
			} else {
				state.available_routes = validQuotes.sort((a, b) => {
					return (b.marketRoute.outAmount - b.feeAmount) - (a.marketRoute.outAmount - a.feeAmount)
				})
			}
			state.available_routes.push(...noQuote)

			if (quote.routes.length > 0) {
				state.selected_route = state.available_routes[0]
				state.selected_collection = {id: state.selected_route.collectionID};
			} else
				state.selected_route = {}
		},
		set_aToB(state, aToB) {
			state.aToB = aToB

			if (aToB && state.selected_route?.collectionID) {
				state.selected_collection = {id: state.selected_route.collectionID};
			}
			state.selected_route = {}
			state.available_routes = []
		},
		set_nft(state, nft) {
			state.selected_nft = nft;
		},
		set_collection(state, collection) {
			state.selected_collection = collection;
		},

		set_route(state, route) {
			state.selected_route = route;
		},

		set_token(state, token) {
			state.selected_token = token
			localStorage.setItem("selected_token", JSON.stringify(token))
		},
		set_loading(state, loading) {
			state.loading = loading;
		},
		clear(state) {
			state.available_routes = []
			// state.selected_token = {} - We dont clear selected token as a QoL change
			state.selected_nft = null
			state.selected_route = {}
			state.loading = false
		},
		set_settings(state, owned) {
			state.settings = owned;
			localStorage.setItem("settings", JSON.stringify(state.settings))
		},
	}
}
